<template>
  <div v-if="text" :class="balloonClass">
    <BalloonArrow :arrowPosition="arrowPosition" />
    {{ text }}
  </div>
</template>

<script>
import { BalloonArrowPosition, BALOON } from '@/constants'

const CSN_BALLOON_CLASS = 'csn-balloon'
const CSN_BALLOON_ABOVE_CLASS = 'csn-balloon-above'
const CSN_BALLOON_BELOW_CLASS = 'csn-balloon-below'

export default {
  name: BALOON,
  components: {
    BalloonArrow: () => import('@/components/svg/BalloonArrow'),
  },
  props: {
    text: String,
    arrowPosition: {
      type: String,
      default: BalloonArrowPosition.TOP_LEFT,
    },
  },
  computed: {
    balloonClass() {
      const positionClass =
        this.arrowPosition === BalloonArrowPosition.TOP_LEFT
          ? CSN_BALLOON_ABOVE_CLASS
          : CSN_BALLOON_BELOW_CLASS

      return `${CSN_BALLOON_CLASS} ${positionClass}`
    },
  },
}
</script>
